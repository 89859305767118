@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* FONT IMPORTS */

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

.calendar {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: #777;
  color: var(--text-color);
  background: #f9f9f9;
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
  background: #fff;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: #1a8fff;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* calendar.css */

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.calendar {
  width: 100%;
  margin: 0 auto;
}

.header,
.days,
.row {
  display: flex;
  flex-direction: row;
}

.header,
.days {
  justify-content: space-between;
}

.row {
  flex-wrap: wrap;
}

.col {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.col-center {
  justify-content: center;
}

.col-start {
  justify-content: flex-start;
}

.col-end {
  justify-content: flex-end;
}

.icon {
  cursor: pointer;
}

.cell {
  position: relative;
  flex-basis: calc(100% / 7);
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}

.cell.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cell.selected {
  background-color: #eee;
}

.number,
.bg {
  position: absolute;
  top: 5px;
  right: 5px;
}

.bg {
  font-size: 60px;
  font-weight: bold;
  z-index: -1;
  opacity: 0.05;
}




/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #fff;
  background: var(--neutral-color);
  border: 1px solid #eee;
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  transition: .25s ease-out;
  color: #1a8fff;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #ccc;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid #eee;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: #f9f9f9;
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #3f51b5 0%, #00aff6 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: #ccc;
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #1a8fff;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

@media (max-width: 767px) {
  .number,
  .bg {
    top: 3px;
    right: 3px;
  }

  .bg {
    font-size: 40px;
  }

  .cell {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .number,
  .bg {
    top: 3px;
    right: 3px;
  }

  .bg {
    font-size: 40px;
  }

  .cell {
    padding: 5px;
    flex-basis: 100%;
    max-width: 100%;
  }

  .calendar .body .col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
